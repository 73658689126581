import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Upload from "../../../src/app/Images/upload.png";
import "react-datepicker/dist/react-datepicker.css";
import "./Banners.css";
import Modal from "react-bootstrap/Modal";
import "./Side.css";
import Imagecard from "../components/Imagecard";
import { baseUrl } from "../../services/config";
import Axios from "axios";
import Updatecomponent from "../components/Updatecomponent";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddWallpaperModal from "../components/AddWallpaperModal/AddWallpaperModal";
// import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";


const Native = ({ foldable = false
    
}) => {
    const location = useLocation();
    const Location = location.pathname;

    console.log(location.pathname);


    const authToken = localStorage.getItem("userToken");

    const [show, setShow] = useState(false);

    const [updateModal, setupdateModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (wallapersList.length >= 2) {
            toast.error("You Can Just Add 2 Native Banners");
        }
        else {
            setShow(true)
        }

    }




    const [wallapersList, setwallapersList] = useState([]);
    const [selectedObject, setselectedObject] = useState({});
    const [loading, setloading] = useState(false);

    const [showlist, setshowlist] = useState([]);
    const [images, setimages] = useState([]);

    const removeImage = (i) => {
        let arr = [...showlist];
        let arr2 = [...images];
        let newArr = arr.filter((item, index) => {
            return index !== i;
        });
        let newArr2 = arr2.filter((item, index) => {
            return index !== i;
        });
        setimages(newArr2);
        setshowlist(newArr);
    };

    useEffect(() => {
        getSideWallapers();
    }, [Location]);


    const getSideWallapers = () => {

        let url1 = 'admin/banners/index/native'
        let url2 = 'admin/fold-able-banners/index/native'
        setloading(true);
        fetch(`${baseUrl}/${url1}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setwallapersList(data.payload);
                setloading(false)
            })
            .catch((err) => {
                setloading(false);
                console.log(err);
            });
    };

    const [url, seturl] = useState("");
    const [type, settype] = useState("");
    const [img, setimg] = useState("");
    const [imgError, setimgError] = useState("");


    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };




    const initialValues = {
        url: "",
    };

    const LoginSchema = Yup.object().shape({
        url: Yup.string()
            .required('Url is Required')
            .matches(/^(http[s]?:\/\/){0,1}(w{3,3}\.)[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]/,
                "Url must begin with http or https:// and end with .com "
            ),

    });

    //start save api

    const saveSide = (values, resetForm) => {

        console.log(values)
        const fdata = new FormData();
        fdata.append("type", "native");
        fdata.append("url", values.url);
        fdata.append("image", img);

        setloading(true);

        let url1 = 'admin/banners';
        let url2 = 'admin/fold-able-banners';

        fetch(`${baseUrl}/${foldable ? url2 : url1}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            body: fdata,
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.metadata.success) {
                    setShow(false);
                    setloading(false);
                    getSideWallapers();
                    resetForm()
                } else {
                    setloading(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    //end save api

    const editWallpaper = (card) => {
        console.log(card);
        setselectedObject(card);
        setupdateModal(true);
    };


    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            if (img) {
                saveSide(values, resetForm)
            } else {
                setimgError('Image is required')
            }
        },
    })
    return (
        <>
            {loading == true ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div class="spinner-border text-dark  p-5 m-5 " role="status">
                        <span class="sr-only ">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginBottom: "10px",
                        }}
                    >
                        <Button
                            // title={wallapersList.length >= 2 ? "You Can Add Only 2 Side Banners" : ""}
                            // disabled={wallapersList.length >= 2 ? true : false}
                            className="mr-2 mb-2"
                            variant="primary"
                            onClick={handleShow}
                        >
                            Add New
                        </Button>
                    </div>
                    <div className="row">
                        {wallapersList &&
                            wallapersList.map((item) => (
                                <Imagecard
                                    foldable={foldable}
                                    onEditClick={(card) => editWallpaper(card)}
                                    reload={() => getSideWallapers()}
                                    card={item}
                                    title="Side"
                                />
                            ))}
                    </div>
                </>
            )}
            {/* add new modal start */}
            {/* <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        style={{ marginLeft: "140px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Side</Modal.Title>
        </Modal.Header>
        {loading == true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div class="spinner-border text-dark  p-5 m-5 " role="status">
              <span class="sr-only ">Loading...</span>
            </div>
          </div>
        ) : (
          <Modal.Body className="row">
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
            >

              <Form.Group className="mb-3 col-md-12" controlId="formBasicEmail">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type={'text'}
                  name="url"
                  placeholder="URL"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "url"
                  )}`}
                  {...formik.getFieldProps("url")}
                />
                {formik.touched.url && formik.errors.url ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.url}</div>
                  </div>
                ) : null}
              </Form.Group>


              <div className="col-12">
                <div
                  className="avatar-upload-input text-center "
                  style={{ marginTop: "20px" }}
                >
                  <img src={Upload} alt="img" />
                  <h2 className="mt-3">Upload File</h2>

                  <div className="avatar-edit-input">
                    <input
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      style={{ textAlignLast: "center" }}
                      type="file"
                      onChange={(e) => {
                        setimgError("")
                        setimg(e.target.files[0])
                      }}
                      name="image"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"

                    />

                    {imgError ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{imgError}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {showlist.map((pic, i) => (
                    <div className="pic" key={i + 1}>
                      <img
                        key={i + 1}
                        height={150}
                        width={150}
                        style={{ margin: 5 }}
                        src={pic}
                      />
                      <button
                        className="danger"
                        onClick={() => removeImage(i)}
                        style={{ border: "none" }}
                      >
                        <p style={{ color: "red" }}>X</p>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary" onClick={formik.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}
            {/*add new modal end */}
            <Updatecomponent
                foldable={foldable}
                type={'native'}
                url={selectedObject.url}
                show={updateModal}
                title="Side"
                hide={() => setupdateModal(false)}
                reload={() => getSideWallapers()}
                wallpaperobject={selectedObject}
            />

            <AddWallpaperModal
                foldable={foldable}
                visible={show}
                type="native"
                handleCloseModal={() => setShow(false)}
                reload={() => getSideWallapers()}

            />
        </>
    );
};

export default Native;
